import React, { useRef, useState } from 'react';
import { CarouselDrag, CarouselHandle } from '../CarouselDrag';
import { ProductCardSize } from '../ProductCardSize';
import { useTranslation } from '~/shared/utils';
import {
    StyledCloseButton,
    StyledContainer,
    StyledHeader,
    StyledMiniCarouselList,
    StyledMiniCarouselListItem,
    StyledNavigationButton,
    StyledSelectorWrapper,
    StyledSizeOverlay,
    StyledSpinnerOverlay,
} from './styled';
import { useAlgoliaContextId, useProduct } from '~/shared/hooks';
import { SizeSelector } from '../SizeSelector';
import { Button } from '../Button';
import { Text } from '../Text';
import Close from '$icons/close.svg';
import { useBasketAdd, useBasketAddArgs } from '~/features/basket';
import { LineSpinner } from '../Spinners';
import ChevronLeft from '$icons/chevron-left.svg';
import ChevronRight from '$icons/chevron-right.svg';
import { Spacer } from '~/theme';

type Props = {
    contextId: string;
    translations?: { headline?: string; cardButtonText?: string };
    trackingEvent?: useBasketAddArgs['trackingEvent'];
};
type ProductArgs = { productId: string; productColorId: string };

export const SizeCarousel = ({ contextId, translations, trackingEvent }: Props) => {
    const { translate } = useTranslation();
    const carouselRef = useRef<CarouselHandle>(null);
    const { add, isLoading } = useBasketAdd();
    const hits = useAlgoliaContextId(contextId);
    const [currentProductArgs, setCurrentProductArgs] = useState<ProductArgs | null>(null);
    const { product, isLoading: basketIsLoading } = useProduct(currentProductArgs);

    const onSizeSelect = async (variantId: string) => {
        if (currentProductArgs?.productColorId) {
            await add({
                productId: currentProductArgs?.productColorId,
                variantId,
                quantity: 1,
                trackingEvent,
            });
        }
        setCurrentProductArgs(null);
    };

    return (
        <StyledContainer>
            <StyledHeader>
                {translations?.headline && <Text variant="display6">{translations?.headline}</Text>}
                <div>
                    <StyledNavigationButton
                        onClick={() => carouselRef.current?.goRight()}
                        variant="tertiary"
                        size="sm"
                        shape="icon"
                        shade="dark"
                        showHoverIndicator={false}
                        children={<ChevronLeft title={translate('general.scrollLeft')} />}
                    />

                    <StyledNavigationButton
                        onClick={() => carouselRef.current?.goLeft()}
                        variant="tertiary"
                        size="sm"
                        shape="icon"
                        shade="dark"
                        showHoverIndicator={false}
                        children={<ChevronRight title={translate('general.scrollRight')} />}
                    />
                </div>
            </StyledHeader>
            <Spacer space="2" />
            <CarouselDrag ref={carouselRef}>
                <StyledMiniCarouselList>
                    {hits?.map((hit) => (
                        <StyledMiniCarouselListItem key={hit.objectID}>
                            <ProductCardSize
                                hit={hit}
                                onClick={setCurrentProductArgs}
                                isLoading={isLoading}
                                buttonText={translations?.cardButtonText}
                                imagePriority="Product"
                            />
                        </StyledMiniCarouselListItem>
                    )) || []}
                </StyledMiniCarouselList>
            </CarouselDrag>
            {product && (
                <StyledSizeOverlay>
                    <StyledCloseButton>
                        <Button
                            onClick={() => setCurrentProductArgs(null)}
                            variant="tertiary"
                            shape="icon"
                            showHoverIndicator={false}
                            size="sm"
                        >
                            <Close title={translate('general.close')} />
                        </Button>
                    </StyledCloseButton>
                    <StyledSelectorWrapper>
                        <SizeSelector name="sizes" onSelect={onSizeSelect} sizes={product.sizes} />
                    </StyledSelectorWrapper>
                </StyledSizeOverlay>
            )}
            {(isLoading || basketIsLoading) && (
                <StyledSpinnerOverlay>
                    <LineSpinner />
                </StyledSpinnerOverlay>
            )}
        </StyledContainer>
    );
};
